import React from 'react';
import BlogArticle from '../../../components/common/BlogArticle';
import SEO from '../../../components/SEO';
import { useScrollToTop } from '../../../hooks/useScrollToTop';
import blogImage from '../../../assets/img/blog/blog-1.jpg';

import './BlogArticlePage.scss';

interface BlogData {
    id: number;
    scrollId?: string;
    text: string;
    type: string;
    subtype: string;
}

interface BlogItems extends Array<BlogData> {}

const BlogArticlePage = () => {
    const contentData = [
        {
            id: 0,
            text: 'Phasing out third-party cookies in their Chrome',
            type: 'margin',
            subtype: 'blog',
        },
        {
            id: 1,
            text: 'Google’s decision to phase out third-party cookies',
            type: 'margin',
            subtype: 'blog',
        },
        {
            id: 2,
            text: 'A few strategies to consider',
            type: 'margin',
            subtype: 'blog',
        },
        {
            id: 3,
            text: 'Conclusion',
            type: 'margin',
            subtype: 'blog',
        },
    ];

    const blogData: BlogItems = [
        {
            id: 0,
            scrollId: 'Phasing out third-party cookies in their Chrome',
            text:
                'Google has announced that they will be phasing out third-party cookies in their Chrome browser. This is a significant shift for online advertising, which has relied heavily on these cookies to track user behavior and target ads. What does this mean for businesses that rely on digital marketing?',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 1,
            text:
                'First, let’s start with some background. Google used third-party cookies to track users’ browsing behavior across the web. Third-party cookies are created by domains different from the one the user is visiting. They are used to collect data for advertising and analytics purposes.',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 2,
            text:
                'For example, let’s say you visit a website that sells shoes. If the website uses third-party cookies, it can track browsing and collect information about the pages you visit, the products you click on, and the ads you interact with. This information can then serve targeted ads for shoes or related products, even when visiting other websites. Google’s use of third-party cookies extended beyond just its advertising products, such as Google Ads. It also allowed third-party ad-tech companies to use cookies to track users across the web and serve targeted ads.',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 3,
            scrollId: 'Google’s decision to phase out third-party cookies',
            text:
                'This practice ends with the announcement of terminating support for third-party cookies in Chrome. Google’s decision to phase out third-party cookies is a response to privacy and data collection concerns in recent years. So, what does this mean for businesses that rely on digital marketing? One immediate impact is that tracking users across multiple websites will take more work. This could make it harder to target ads to specific groups of people and may lead to less effective advertising campaigns.',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 4,
            text:
                'However, this change also presents an opportunity to shift towards more privacy-focused and transparent advertising practices. Businesses must explore new ways to reach their target audience without relying on third-party cookies and deliver personalized ads while respecting user privacy. By exploring alternative targeting methods, focusing on first-party data, and collaborating with partners, they can continue to drive results from their advertising campaigns in a post-cookie world.',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 5,
            scrollId: 'A few strategies to consider',
            text: 'Here are a few strategies to consider :',
            type: 'left',
            subtype: 'blog',
        },
        {
            id: 6,
            text:
                'Focus on first-party data: Instead of relying on third-party cookies, businesses can collect and leverage their first-party data, such as data from website analytics, customer interactions, and purchase histories. This can help them build a complete picture of their audience and deliver targeted ads.',
            type: 'margin',
            subtype: 'blog',
        },
        {
            id: 7,
            text:
                'Use contextual targeting: Contextual targeting involves analyzing the content of a webpage to determine its context and delivering ads that are relevant to that context. For example, an ad for running shoes might be shown on a webpage that features an article about running. This approach can be effective even without access to user data.',
            type: 'margin',
            subtype: 'blog',
        },
        {
            id: 8,
            text:
                'Invest in alternative targeting methods: Several emerging technologies and targeting strategies can help businesses deliver personalized ads without relying on third-party cookies. For example, Google’s FLoC (Federated Learning of Cohorts) technology groups users with similar interests into “cohorts” and allows advertisers to target those cohorts with relevant ads.',
            type: 'margin',
            subtype: 'blog',
        },
        {
            id: 9,
            text:
                'Collaborate with partners: Businesses can work with trusted partners, such as publishers or other advertisers, to gather user data and deliver targeted ads. This approach can help them reach their target audience while complying with privacy regulations',
            type: 'margin',
            subtype: 'blog',
        },
        {
            id: 10,
            scrollId: 'Conclusion',
            text:
                'In conclusion, Google’s decision to phase out third-party cookies is a significant change for the ad-tech industry. While it may present some challenges for businesses that rely on digital marketing, it also allows shifting toward more privacy-focused and transparent advertising practices. By focusing on first-party data and exploring alternative targeting methods, businesses can continue to reach their audience effectively in a changing online landscape. At Blackbird, we believe that this change is a step towards a more transparent and privacy-focused online environment. It’s important for businesses to stay informed and adapt to these changes in order to continue reaching their target audience effectively.',
            type: 'mb',
            subtype: 'blog',
        },
    ];

    useScrollToTop();

    return (
        <div>
            <SEO
                pageTitle="Blackbird Lab - Blog"
                pageDescription="Say Goodbye to Third-Party Cookies: What Google’s Move Means for Digital Advertising"
                pageImage="https://blackbird-image-cdn.s3.eu-central-1.amazonaws.com/blog/articles/say-goodbye-to-third-party-cookies.png"
            />
            <BlogArticle
                title="Say Goodbye to Third-Party Cookies: What Google’s Move Means for Digital Advertising"
                image={blogImage}
                author="Yurii Drozd"
                date="May 31, 2023"
                blogData={blogData}
                contentData={contentData}
            />
        </div>
    );
};

export default BlogArticlePage;
